import React from "react"

import * as formStyles from "./form-answer.module.scss"
import { IMG } from "../../const"

const Answer = () => (
  <>
    <h3 className={formStyles.answerSubtitle}>
      Thanks for your trust. We're going to contact you soon.
    </h3>
    <div className={formStyles.formAnswerImgWrapper}>
      <img
        src={`${IMG.INDEX}/img/form-response.svg`}
        alt="rocket launch"
        width="250"
        height="250"
      />
    </div>
  </>
)

export default Answer
