import {
  useEffect,
  useMemo,
  useState,
  useRef,
  createRef,
  useCallback,
} from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

export const recaptchaRef = createRef()
const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Zа-яА-ЯіїєґІЇЄҐ\s-]*$/)
    .trim()
    .required(),
  email: yup
    .string()
    .email()
    .required()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
})

const allowedFileExtensions = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "text/plain",
  "text/csv",
  "application/vnd.oasis.opendocument.text",
  "application/msword",
]

const useHandleContactForm = () => {
  const [showCaptchaMessage, setShowCaptchaMessage] = useState(false)
  const [showFormAnswer, setShowFormAnswer] = useState(false)
  const [reCaptchaResponse, setReCaptchaResponse] = useState()
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [isDisabledButton, setDisabledButton] = useState(true)
  const formRef = useRef(null)

  const maxLength = 500
  const [currentNumber, setCurrentNumber] = useState(maxLength)

  const [isMaxSize, setMaxSize] = useState(false)
  const MAX_SIZE_OF_FILES = 15000000

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const [isFilledName, setFilledName] = useState(false)
  const [isFilledEmail, setFilledEmail] = useState(false)
  const [isFilesLimited, setFilesLimited] = useState(false)
  const MAX_FILES_COUNT = 3 // we can upload up to 3 files overall

  useEffect(() => {
    reCaptchaResponse && isFilledName && isFilledEmail
      ? setDisabledButton(false)
      : setDisabledButton(true)
  }, [reCaptchaResponse, isFilledName, isFilledEmail])

  let onChangeCaptcha = async value => {
    setShowCaptchaMessage(false)
    setReCaptchaResponse(value)
  }

  const validateFile = type => {
    return allowedFileExtensions.indexOf(type) !== -1
  }

  const handleAddFile = useCallback(
    e => {
      if (
        e.target.files.length > MAX_FILES_COUNT ||
        e.target.files.length + uploadedFiles.length > MAX_FILES_COUNT
      )
        setFilesLimited(true)

      if (isMaxSize) setMaxSize(false)
      let filesSize = 0

      // count size of uploadedFiles
      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach(file => {
          filesSize = filesSize + file.size
        })
      }

      if (uploadedFiles.length < MAX_FILES_COUNT) {
        const filesToUpload = []
        // we can upload up to 3 files overall
        let iterationsCount =
          e.target.files.length > MAX_FILES_COUNT - uploadedFiles.length
            ? MAX_FILES_COUNT - uploadedFiles.length
            : e.target.files.length

        for (let i = 0; i < iterationsCount; i++) {
          if (validateFile(e.target.files[i].type)) {
            filesToUpload.push(e.target.files[i])
            // add to size of uploadedFiles
            filesSize = filesSize + e.target.files[i].size
          }
        }

        if (filesSize <= MAX_SIZE_OF_FILES)
          setUploadedFiles([...uploadedFiles, ...filesToUpload])
      }

      if (filesSize > MAX_SIZE_OF_FILES) setMaxSize(true)
    },
    [isMaxSize, uploadedFiles]
  )

  const handleRemoveFile = useCallback(
    index => {
      if (isFilesLimited) setFilesLimited(false)

      const filteredFiles = uploadedFiles.filter((_, i) => i !== index)
      setUploadedFiles(filteredFiles)

      // count size of uploadedFiles
      let filesSize = 0

      if (filteredFiles.length > 0) {
        filteredFiles.forEach(file => {
          filesSize = filesSize + file.size
        })
      }
      filesSize > MAX_SIZE_OF_FILES ? setMaxSize(true) : setMaxSize(false)
    },
    [uploadedFiles, isFilesLimited]
  )

  const onSubmit = useCallback(
    async data => {
      if (process.env.NODE_ENV !== "development")
        window.ga("send", "event", "SendRequest", "click")

      const pageUrl = window.location.href
      let formData = new FormData()
      formData.append("name", data.name)
      formData.append("email", data.email)
      formData.append("comment", data.comment)
      formData.append("radio_btns", JSON.stringify(data.radio_btns))
      formData.append("page_url", pageUrl)

      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append("file_" + i, uploadedFiles[i], uploadedFiles[i].name)
      }

      if (reCaptchaResponse) {
        recaptchaRef.current.reset()
        setDisabledButton(true)

        const requestOptions = {
          method: "POST",
          processData: false,
          contentType: false,
          body: formData,
          mode: "no-cors",
        }

        fetch("/career/send/", requestOptions)
          .then(response => {
            setShowFormAnswer(true)
            formRef.current.parentElement.parentElement.parentElement.scrollIntoView()
          })
          .then(data => {
            setDisabledButton(false)
            return console.log("callback after sending")
          })
          .catch(err => console.log("err", err))
      } else {
        setShowCaptchaMessage(true)
      }
    },
    [reCaptchaResponse, uploadedFiles]
  )

  const onChangeInput = useCallback(
    e => {
      const inputName = e.target.name
      const inputValue = getValues(inputName)

      if (inputName === "name") {
        if (inputValue) {
          trigger([inputName])

          setFilledName(true)
        } else setFilledName(false)
      }

      if (inputName === "email") {
        clearErrors(inputName)

        if (inputValue) {
          setFilledEmail(true)
        } else setFilledEmail(false)
      }
    },
    [trigger, getValues, clearErrors]
  )

  const onFieldBlur = useCallback(
    e => {
      const inputName = e.target.name
      const inputValue = getValues(inputName)

      if (inputValue) trigger([inputName])
    },
    [getValues, trigger]
  )

  const getExtension = fileName => fileName.split(".").pop().toLowerCase()
  const getFileName = fileName => fileName.split(".")[0]

  const countCharacters = useCallback(
    e => {
      const currentLength = e.target.value.length
      setCurrentNumber(maxLength - currentLength)
    },
    [maxLength]
  )

  return useMemo(
    () => ({
      register,
      handleSubmit,
      errors,
      showCaptchaMessage,
      showFormAnswer,
      isDisabledButton,
      currentNumber,
      formRef,
      maxLength,
      uploadedFiles,
      MAX_FILES_COUNT,
      isMaxSize,
      isFilesLimited,
      countCharacters,
      onFieldBlur,
      onChangeInput,
      onSubmit,
      handleAddFile,
      handleRemoveFile,
      getFileName,
      getExtension,
      onChangeCaptcha,
    }),
    [
      register,
      handleSubmit,
      errors,
      showCaptchaMessage,
      showFormAnswer,
      isDisabledButton,
      currentNumber,
      formRef,
      maxLength,
      uploadedFiles,
      MAX_FILES_COUNT,
      isMaxSize,
      isFilesLimited,
      countCharacters,
      onFieldBlur,
      onChangeInput,
      onSubmit,
      handleAddFile,
      handleRemoveFile,
      getFileName,
      getExtension,
      onChangeCaptcha,
    ]
  )
}

export default useHandleContactForm
