import React, { useEffect } from "react"
import cx from "classnames"
import ScrollReveal from "scrollreveal"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/mobile-development.module.scss"
import {
  MOBILE_DEVELOPMENT_SERVICES,
  LOGO_TRUSTED_BY,
  TECHNOLOGIES,
} from "../components/mobile-development/const"
import { IMG } from "../const"
import Accordion from "../components/accordion"
import Media from "../components/Media"
import TechnologyPartnerCarousel from "../components/carousels/TechnologyPartnerCarousel"
import Titles from "../components/mobile-development/Titles"
import ContactsForm from "../components/contact-form/contacts"

const CaseStudies = loadable(() => import("../components/case-studies"))

const MobileDevelopment = () => {
  useEffect(() => {
    ScrollReveal().reveal(`#trusted-by li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 100,
      delay: 100,
    })
    ScrollReveal().reveal(`#tech-stack li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
  }, [])

  return (
    <Layout pageTitle="Mobile Development">
      {({ width }) => {
        return (
          <>
            <main className={cx(styles.mobileDevelopmentBlock, "glossy")}>
              <article>
                <div className={styles.mobileDevelopmentBlock__headline}>
                  <div
                    className={styles.mobileDevelopmentBlock_headingCarousel}
                  >
                    <Titles />
                  </div>
                  <p>
                    We create fantastic mobile experiences, offering long-term
                    advantages to your business.
                    <Media query="(min-width: 901px)">
                      {match =>
                        match ? (
                          <Link
                            to="#mobile-development-contact-form"
                            className="goldFatLink mobile-estimate"
                          >
                            Project Estimate
                          </Link>
                        ) : null
                      }
                    </Media>
                  </p>
                  <div className={styles.trusted} id="trusted-by">
                    <h2 className={styles.trustedH2}>
                      <em>We are trusted by:</em>
                    </h2>
                    <ul>
                      {LOGO_TRUSTED_BY.map(item => (
                        <li key={item.id}>
                          <img
                            src={`${IMG.MOBILE_DEVELOPMENT}/${item.logo}.svg`}
                            alt={item.name}
                            width={item.width}
                            height={item.height}
                            loading="lazy"
                          />
                        </li>
                      ))}
                    </ul>

                    <Media query="(max-width: 900px)">
                      {match =>
                        match ? (
                          <Link
                            to="#mobile-development-contact-form"
                            className="goldFatLink mobile-estimate"
                          >
                            Project Estimate
                          </Link>
                        ) : null
                      }
                    </Media>
                  </div>
                </div>

                <section
                  className={cx(
                    styles.leadingBusiness,
                    "commonGlossy__article__section upTo2cols"
                  )}
                >
                  <h2>Leading businesses to achievement</h2>
                  <div className={styles.leadingBusiness__summary}>
                    <p>
                      <mark>
                        We create powerful mobile apps that are developed and
                        optimized to deliver key functionalities and content to
                        users through an optimized and intuitive interface.
                      </mark>
                    </p>
                    <p>
                      Our professional teams attentively drill down into your
                      challenges and objectives, helping you to deliver a
                      compelling product with a unique value proposition. On
                      another side, we understand user needs and can explicitly
                      identify the best features for your mobile solution.
                    </p>
                  </div>
                  <div className={styles.leadingBusiness__tech}>
                    <ul
                      className={styles.leadingBusiness__techLogos}
                      id="tech-stack"
                    >
                      {TECHNOLOGIES.map(technology => (
                        <li key={technology.name}>
                          <figure>
                            <img
                              src={`${IMG.ICONS}/logos/${technology.icon}`}
                              width="32"
                              height="32"
                              loading="lazy"
                              alt={technology.name}
                            />
                            <figcaption>{technology.name}</figcaption>
                          </figure>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <p className={cx(styles.leadingBusiness__capture, "fs1o5")}>
                    <em>
                      <strong>
                        Leading your business and customers to an improved user
                        experience level is our goal. Our solutions will meet
                        your business challenges head-on.
                      </strong>
                    </em>
                  </p>
                </section>

                <section
                  className={cx(
                    styles.services,
                    "commonGlossy__article__section"
                  )}
                >
                  <h1 className={styles.servicesH2}>
                    Mobile app development Services
                  </h1>
                  <Accordion options={MOBILE_DEVELOPMENT_SERVICES} />
                </section>

                <div className="commonGlossy__article__case-studies">
                  <CaseStudies title="" screenWidth={width} />
                </div>

                <section className="commonGlossy__article__section indentTop1o2 indentBot1o6">
                  <p>
                    <mark className={styles.dash}>
                      Want to know how much your future application development
                      project will cost? We can estimate the cost to build an
                      app.
                    </mark>
                  </p>
                  <Link
                    to="#mobile-development-contact-form"
                    className="goldFatLink mobile-estimate-app"
                  >
                    Estimate app dev
                  </Link>
                </section>

                <section className={styles.technologyPartner}>
                  <div className="commonGlossy__article__section indentTop1o6 indentBot0">
                    <h2>
                      LaSoft is <br />
                      your one-stop shop technology partner:
                    </h2>
                  </div>
                  <div className={styles.technologyPartner__carousel}>
                    <TechnologyPartnerCarousel screenWidth={width} />
                  </div>
                </section>

                <section className={styles.partnership}>
                  <picture>
                    <source
                      media="(max-width: 416px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_416.avif,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_832.avif 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1248.avif 3x
                      `}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 416px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_416.webp,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_832.webp 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1248.webp 3x
                      `}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 416px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_416.jpg,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_832.jpg 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1248.jpg 3x
                      `}
                    />
                    <source
                      media="(min-width: 1441px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.avif,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_3840.avif 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2304.avif 3x
                      `}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 1441px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.webp,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_3840.webp 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2304.webp 3x
                      `}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 1441px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.jpg,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_3840.jpg 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_5760.jpg 3x
                      `}
                    />
                    <source
                      media="(min-width: 1025px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1536.avif,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2880.avif 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_5760.avif 3x
                      `}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 1025px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1536.webp,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2880.webp 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_5760.webp 3x
                      `}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 1025px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1536.jpg,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2880.jpg 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_4320.jpg 3x
                      `}
                    />
                    <source
                      media="(min-width: 769px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1024.avif,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.avif 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_3072.avif 3x
                      `}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 769px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1024.webp,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.webp 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_3072.webp 3x
                      `}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 769px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1024.jpg,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.jpg 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_3072.jpg 3x
                      `}
                    />
                    <source
                      media="(min-width: 417px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_768.avif,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1536.avif 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2304.avif 3x
                      `}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 417px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_768.webp,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1536.webp 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2304.webp 3x
                      `}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 417px)"
                      srcSet={`
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_768.jpg,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_1536.jpg 2x,
                        ${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2304.jpg 3x
                      `}
                    />
                    <img
                      src={`${IMG.MOBILE_DEVELOPMENT}/bg-partnership_2048.jpg`}
                      alt="Our team"
                      width="1440"
                      height="986"
                      loading="lazy"
                    />
                  </picture>

                  <div className="commonGlossy__article__section indentTop1o2 indentBot1o6">
                    <p className={styles.largeWiteText}>
                      Try new methods of app development, powered by trusted
                      partnerships.
                      <span>
                        <Link
                          to="#mobile-development-contact-form"
                          className="goldFatLink mobile-client"
                        >
                          become a client
                        </Link>
                      </span>
                    </p>
                  </div>
                </section>
              </article>
            </main>
            <section className="footerForm">
              <div id="mobile-development-contact-form">
                <ContactsForm formStatID="CommonFooter" />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default MobileDevelopment
