import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import ReCAPTCHA from "react-google-recaptcha"

import useHandleContactForm, { recaptchaRef } from "./useHandelContactForm"
import * as formStyles from "./contacts.module.scss"
import Answer from "./formAnswer"

const ContactsForm = props => {
  const {
    register,
    handleSubmit,
    errors,
    showCaptchaMessage,
    showFormAnswer,
    isDisabledButton,
    formRef,
    uploadedFiles,
    MAX_FILES_COUNT,
    isFilesLimited,
    onFieldBlur,
    onChangeInput,
    onSubmit,
    handleAddFile,
    handleRemoveFile,
    getFileName,
    getExtension,
    onChangeCaptcha,
  } = useHandleContactForm()

  return (
    <div>
      {showFormAnswer ? (
        <div
          className={formStyles.formAnswer}
          ref={formRef}
          id="MessageSentSuccessfully"
        >
          <Answer />
        </div>
      ) : (
        <form
          className={cx(
            formStyles.form,
            props.formStatID && `FeedbackForm__${props.formStatID}`
          )}
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className={formStyles.formContent}>
            <div>
              <h3 className={formStyles.checkboxesTitle}>
                We bring ideas to&nbsp;life
              </h3>
              <p>
                Become our client and get the best services and emotions with us
              </p>
              <h4>I’m interested in:</h4>

              <div className={formStyles.formGroup}>
                <div className={formStyles.checkboxItem}>
                  <input
                    type="checkbox"
                    value="Project estimation"
                    name="radio_btns"
                    id="project-estimation"
                    className={formStyles.hiddenCheckbox}
                    ref={register}
                    defaultChecked
                  />
                  <label htmlFor="project-estimation">Project estimation</label>
                </div>
                <div className={formStyles.checkboxItem}>
                  <input
                    type="checkbox"
                    value="Web Development"
                    name="radio_btns"
                    id="web-development"
                    className={formStyles.hiddenCheckbox}
                    ref={register}
                  />
                  <label htmlFor="web-development">Web Development</label>
                </div>
                <div className={formStyles.checkboxItem}>
                  <input
                    type="checkbox"
                    value="Mobile Development"
                    name="radio_btns"
                    id="mobile-development"
                    className={formStyles.hiddenCheckbox}
                    ref={register}
                  />
                  <label htmlFor="mobile-development">Mobile Development</label>
                </div>
                <div className={formStyles.checkboxItem}>
                  <input
                    type="checkbox"
                    value="Product Design"
                    name="radio_btns"
                    id="design"
                    className={formStyles.hiddenCheckbox}
                    ref={register}
                  />
                  <label htmlFor="design">Product Design</label>
                </div>
                <div className={formStyles.checkboxItem}>
                  <input
                    type="checkbox"
                    value="Dashboard Expertise"
                    name="radio_btns"
                    id="dashboard-expertise"
                    className={formStyles.hiddenCheckbox}
                    ref={register}
                  />
                  <label htmlFor="dashboard-expertise">
                    Dashboard Expertise
                  </label>
                </div>
              </div>
            </div>

            <div className={formStyles.inputsRow}>
              <div
                className={cx(formStyles.formGroup, {
                  "has-error": errors.name,
                })}
              >
                <label
                  htmlFor="name"
                  className={cx(formStyles.contactLabel, formStyles.required)}
                >
                  Enter your full name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={formStyles.contactInput}
                  ref={register}
                  onChange={onChangeInput}
                />
                {errors.name && (
                  <div className={formStyles.errorMessage}>
                    Please enter your full name using only letters
                  </div>
                )}
              </div>
              <div
                className={cx(formStyles.formGroup, {
                  "has-error": errors.email,
                })}
              >
                <label
                  htmlFor="email"
                  className={cx(formStyles.contactLabel, formStyles.required)}
                >
                  Enter your email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={formStyles.contactInput}
                  ref={register}
                  onChange={onChangeInput}
                  onBlur={onFieldBlur}
                />
                {errors.email && (
                  <div className={formStyles.errorMessage}>
                    Please enter a valid email address
                  </div>
                )}
              </div>
            </div>

            <div className={formStyles.formGroup}>
              <label htmlFor="comment" className={formStyles.contactLabel}>
                Enter your message
              </label>
              <textarea
                className={formStyles.contactTextarea}
                ref={register}
                id="comment"
                name="comment"
              />
            </div>

            <div className={formStyles.sendContainer}>
              <div className={formStyles.inputFileBox}>
                <label className={formStyles.labelFile}>
                  <input
                    ref={register}
                    key={+Date.now()}
                    type="file"
                    accept="image/png,image/jpg,image/jpeg,.odt,.pdf,.txt,.doc,.docx,application/msword"
                    name="file_name"
                    className={formStyles.inputFile}
                    onChange={handleAddFile}
                    data-multiple-caption="{count} files selected"
                    multiple={true}
                    disabled={uploadedFiles.length >= MAX_FILES_COUNT}
                  />
                  <span className={formStyles.span}>ATTACH A FILE</span>
                </label>
                <div className={formStyles.attachedFiles}>
                  {uploadedFiles.map((file, i) => (
                    <div className={formStyles.file} key={i}>
                      <span className={formStyles.fileName}>
                        {getFileName(file.name)}
                      </span>
                      <span className={formStyles.fileExt}>
                        .{getExtension(file.name)}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="14"
                        viewBox="0 0 24 24"
                        width="14"
                        fill="crimson"
                        onClick={() => handleRemoveFile(i)}
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </div>
                  ))}
                  <div
                    className={
                      isFilesLimited
                        ? formStyles.warningActive
                        : formStyles.warning
                    }
                  >
                    Only 3 files could be added
                  </div>
                </div>
              </div>
              <div className={cx("g-recaptcha", { error: showCaptchaMessage })}>
                <ReCAPTCHA
                  sitekey={`${process.env.RECAPTCHA_SITE_KEY}`}
                  ref={recaptchaRef}
                  onChange={onChangeCaptcha}
                />
                {showCaptchaMessage && (
                  <div className={"recaptcha-error-message"}>
                    Please verify that you are not a robot.
                  </div>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="goldButton"
              disabled={isDisabledButton}
            >
              {props.buttonTitle ? props.buttonTitle : "Send request"}
            </button>
          </div>

          <div className={formStyles.additional}>
            <h3>What’s next?</h3>
            <ol>
              <li>
                We’ll contact you within 24 hours with more information on our
                next step. In the meantime, you can check out our{" "}
                <Link to="/portfolio/marketing_tech/">case studies</Link> and
                our <a href="/blog/">blog</a>.
              </li>
              <li>
                Our next step will be to collect all the requirements for your
                project and clarify your business objectives, and expectations
                for our cooperation.
              </li>
              <li>After that, we’ll develop a proposal for you.</li>
            </ol>
          </div>
        </form>
      )}
    </div>
  )
}

export default ContactsForm
