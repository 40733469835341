// extracted by mini-css-extract-plugin
export var additional = "contacts-module--additional--06650";
export var attachedFiles = "contacts-module--attached-files--746e1";
export var billetShadow = "contacts-module--billet-shadow--f6fea";
export var checkboxItem = "contacts-module--checkbox-item--573e9";
export var checkboxesTitle = "contacts-module--checkboxes-title--5c58f";
export var colorScheme__background__grayPale = "contacts-module--colorScheme__background__gray-pale--e2293";
export var contactInput = "contacts-module--contact-input--20c8b";
export var contactLabel = "contacts-module--contact-label--ef067";
export var contactTextarea = "contacts-module--contact-textarea--2ffb4";
export var errorMessage = "contacts-module--error-message--12c64";
export var file = "contacts-module--file--5f0a5";
export var fileExt = "contacts-module--file-ext--55850";
export var fileName = "contacts-module--file-name--f2ca0";
export var form = "contacts-module--form--97a75";
export var formAnswer = "contacts-module--form-answer--e5ec3";
export var formBottom = "contacts-module--form-bottom--ce875";
export var formContent = "contacts-module--form-content--fe9af";
export var formGroup = "contacts-module--form-group--c606c";
export var formHiddenImgWrapper = "contacts-module--form-hidden-img-wrapper--05453";
export var hiddenCheckbox = "contacts-module--hidden-checkbox--1bcef";
export var icomoon = "contacts-module--icomoon--1337b";
export var inputFile = "contacts-module--input-file--f3d9b";
export var inputFileBox = "contacts-module--input-file-box--b1b45";
export var inputsRow = "contacts-module--inputs-row--1197e";
export var labelFile = "contacts-module--label-file--d8364";
export var laformSection = "contacts-module--laform-section--e692f";
export var lowercase = "contacts-module--lowercase--f7122";
export var mFileInputContainer = "contacts-module--m-file-input-container--21f3a";
export var required = "contacts-module--required--193a2";
export var selected = "contacts-module--selected--f79d5";
export var sendBtn = "contacts-module--send-btn--f4de8";
export var sendContainer = "contacts-module--send-container--79e16";
export var span = "contacts-module--span--92177";
export var transition = "contacts-module--transition--46a79";
export var transitionBackground = "contacts-module--transition-background--9e13c";
export var transitionReverse = "contacts-module--transition-reverse--168e7";
export var uppercase = "contacts-module--uppercase--4a793";
export var warning = "contacts-module--warning--dcfa9";
export var warningActive = "contacts-module--warning-active--d7b6c";